import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
} from 'amazon-cognito-identity-js';
import { environment } from 'src/environments/environment';
import { CognitoService, IUser } from '../../services/cognito.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { loadUser } from '../../auth.actions';
import { Store } from '@ngrx/store';
import { User } from 'src/app/shared/models/user.model';
import { AppState } from 'src/app/app.reducer';
import { FirebaseApp } from '@angular/fire/app';
import { getMessaging, getToken } from '@angular/fire/messaging';
import { RegisteredDevicesService } from 'src/app/shared/services/registered-devices.service';
import { UserService } from 'src/app/dashboard/services/user.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SetupMfaComponent } from 'src/app/dashboard/components/user-profile/setup-mfa/setup-mfa.component';
@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.sass'],
})
export class SignInComponent implements OnInit {
  loading: boolean;
  user: IUser;
  unregisteredMail: boolean = false;
  loginForm: FormGroup;
  loginError: boolean = false;
  token: string | undefined;
  constructor(private userService: UserService,
    private registeredDevice: RegisteredDevicesService, private setupMFaDialog: MatDialog, private afApp: FirebaseApp, private store: Store<AppState>, private router: Router, private cognitoService: CognitoService, private fb: FormBuilder, private spinner: SpinnerService) {
    this.loginForm = this.fb.group({
      email: new FormControl('', [Validators.email, Validators.required]),
      password: new FormControl('', [Validators.required]),
    });
    this.loading = false;
    this.user = {} as IUser;
  }
  ngOnInit(): void {
    this.requestPermission();
  }

  public onSubmit(form: FormGroup) {
    this.user = { email: form.get('email')?.value, password: form.get('password')?.value, showPassword: false, code: '', name: '' };
    this.signIn();
  }


  requestPermission() {
    const messaging = getMessaging(this.afApp);
    getToken(messaging,
      { vapidKey: environment.firebase.applicationServerKey }).then(
        (currentToken) => {
          if (currentToken) {
            this.token = currentToken;
          } else {
            console.log('No registration token available. Request permission to generate one.');
          }
        }).catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
        });
  }

  async checkUserEmail() {
    if (this.loginForm.get('email')?.value) {
      this.unregisteredMail = false;

      const result = await this.userService.checkUserEmail(this.loginForm.get('email')?.value);
      this.unregisteredMail = !result;
    }

  }

  public signIn(): void {
    this.unregisteredMail = false;
    this.loginError = false;
    let ref = this.spinner.start();

    this.cognitoService.signIn(this.user.email, this.user.password)
      .then(async (res) => {
        console.log(res);
        const cognitoSession = await this.cognitoService.getSession();
        console.log(cognitoSession);
        const cognitoUser = await this.cognitoService.getAuthUser();
        const userGroup = cognitoSession['accessToken']['payload']['cognito:groups'][0];

        this.store.dispatch(loadUser({ email: this.user.email, role: userGroup }));
        this.loginError = false;

        if (cognitoUser.preferredMFA === 'NOMFA' && cognitoUser.attributes['custom:user-group'] === 'aguacate_operator') {
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = false;
          dialogConfig.autoFocus = true;
          dialogConfig.maxHeight = '90vh';
          dialogConfig.maxWidth = '100vw';

          this.setupMFaDialog.open(SetupMfaComponent, dialogConfig);
        }

        if (cognitoUser.attributes['custom:user-group'] === 'aguacate_retail_client' || cognitoUser.attributes['custom:user-group'] === 'aguacate_retail_cashier') {
          this.router.navigate(['/retail/home']);
        } else {
          this.router.navigate(['/wallet']);
        }

      })
      .catch(() => {
        this.loginError = true;
        this.checkUserEmail();
        this.loading = false;
      })
      .finally(() => {
        this.spinner.stop(ref);
      });
  }

}

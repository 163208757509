<mat-toolbar color="primary" class="h-20">
  <button mat-icon-button  (click)="sidenav.toggle()" matTooltip="Menu">
    <mat-icon>menu</mat-icon>
  </button>
  <img class="w-16" src="assets/img/aguacate-icon.png" routerLink="/wallet">
  <span class="toolbar-item-spacer"></span>
  	<div class="toolbar-detail flex flex-col items-end  align-middle">
      <p class="font-medium">Bienvenido {{ userId }}</p>
      <span> {{userName}} </span>
    </div>

  <button class="icon-my-account" mat-icon-button matTooltip="Mi Cuenta" [matMenuTriggerFor]="userAccountMenu">
    <mat-icon>account_circle</mat-icon>
  </button>
  <mat-menu #userAccountMenu [overlapTrigger]="false" yPosition="below">
    <button mat-menu-item routerLink="client/user-profile" [disabled]="!isEnabledUser" *ngIf="userRole === rolesEnum.aguacate_client">
      <mat-icon>person</mat-icon><span>Mi cuenta</span>
    </button>
    <!-- <button mat-menu-item routerLink="#">
          <mat-icon>settings</mat-icon><span>Configuración</span>
      </button> -->
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="signOut()">
      <mat-icon>exit_to_app</mat-icon>Cerrar sesión
    </button>
  </mat-menu>
</mat-toolbar>

<mat-sidenav-container class="app-container" >
  <mat-sidenav #sidenav mode="push" opened="false" color="primary"  [class.mat-elevation-z4]="true" class="app-sidenav">
    <mat-nav-list>
      <mat-list-item routerLink="/wallet" class="menu-element" (click)="closeSidenav(sidenav)">

        <span class="pl-5 flex flex-row justify-start gap-8">    <span class="icon-home"></span> Inicio</span>
      </mat-list-item>
      <!-- Menu usuario -->
      <ng-container *ngIf="userRole=== rolesEnum.aguacate_client" >

        <mat-list-item routerLink="/wallet/client/orders" class="menu-element" (click)="closeSidenav(sidenav)">
          <span class="pl-5 flex flex-row justify-start gap-8"><span><mat-icon class="h-5 w-5" style="font-size: 20px;">query_builder</mat-icon></span>Historial de transacciones</span>
        </mat-list-item>

        <mat-list-item routerLink="/wallet/client/remittance" class="menu-element" (click)="closeSidenav(sidenav)">
          <span class="pl-5 flex flex-row justify-start gap-8"><span><mat-icon class="h-5 w-5" style="font-size: 20px;">payment</mat-icon></span>Remesas</span>
        </mat-list-item>

        <mat-list-item routerLink="client/recipients" class="menu-element" (click)="closeSidenav(sidenav)">
          <span class="pl-5 flex flex-row justify-start gap-8"><span><mat-icon class="h-5 w-5" style="font-size: 20px;">assignment_ind</mat-icon></span> Beneficiarios Fiat</span>
        </mat-list-item>

        <mat-expansion-panel [class.mat-elevation-z0]="true" class="menu-element -ml-2" *ngIf="userName2==='f751e3d84d03360fbf81118174fa14c8c112ccec56a34d153b6837cbb28c66d3'||userName2==='06c285de186979ebd4d8fa3422e16065fa0998ac46c75c53fe2e9d39d0f802bf'">
          <mat-expansion-panel-header >
           <span class="pl-5 flex flex-row justify-start gap-8">    <mat-icon class="h-5 w-5" style="font-size: 20px;">pan_tool</mat-icon> Retiros</span>

          </mat-expansion-panel-header >
          <mat-nav-list>
            <mat-list-item routerLink="client/withdraw" class="menu-element" (click)="closeSidenav(sidenav)">
              <span class="pl-5 flex flex-row justify-start gap-8">    <mat-icon class="h-5 w-5" style="font-size: 20px;">pan_tool</mat-icon> Realizar retiro</span>
            </mat-list-item>

            <mat-list-item routerLink="client/withdraw-accounts" class="menu-element" (click)="closeSidenav(sidenav)">
              <span class="pl-5 flex flex-row justify-start gap-8">    <mat-icon class="h-5 w-5" style="font-size: 20px;">pan_tool</mat-icon> Cuentas de retiro</span>
            </mat-list-item>
          </mat-nav-list>
        </mat-expansion-panel>

        <mat-list-item routerLink="client/cripto-home" class="menu-element" (click)="closeSidenav(sidenav)">
          <span class="pl-5 flex flex-row justify-start gap-8">    <span class="icon-criptomoneda"></span> Criptomonedas</span>
        </mat-list-item>

        <mat-list-item routerLink="client/recipient-cripto" class="menu-element" (click)="closeSidenav(sidenav)">
          <span class="pl-5 flex flex-row justify-start gap-8">    <span class="icon-criptomoneda"></span> Beneficiarios Cripto</span>
        </mat-list-item>

        <mat-list-item routerLink="client/zelle-home" class="menu-element" (click)="closeSidenav(sidenav)">
          <span class="pl-5 flex flex-row justify-start gap-8">    <span class="icon-criptomoneda"></span> Zelle</span>
        </mat-list-item>

        <mat-list-item routerLink="client/recipient-zelle" class="menu-element" (click)="closeSidenav(sidenav)">
          <span class="pl-5 flex flex-row justify-start gap-8">    <span class="icon-criptomoneda"></span> Beneficiarios Zelle</span>
        </mat-list-item>

        <mat-expansion-panel [class.mat-elevation-z0]="true" class="menu-element -ml-2" *ngIf="canSeePayers===1">
          <mat-expansion-panel-header >
           <span class="pl-5 flex flex-row justify-start gap-8">    <span class="icon-pagadores"></span> Pagadores</span>

          </mat-expansion-panel-header >
          <mat-nav-list>
            <mat-list-item routerLink="client/registerPayer" class="menu-element" (click)="closeSidenav(sidenav)">
              <mat-icon>person_add_alt_1</mat-icon> <span class="pl-5">Crear</span>
            </mat-list-item>
            <mat-list-item routerLink="client/payerList" class="menu-element" (click)="closeSidenav(sidenav)">
              <mat-icon>contacts</mat-icon> <span class="pl-5">Mis pagadores  </span>
            </mat-list-item>
          </mat-nav-list>

        </mat-expansion-panel>

      </ng-container>
      <!-- Menu operario -->
      <ng-container *ngIf="userRole=== rolesEnum.aguacate_operator" >
        <mat-list-item routerLink="/wallet/admin/dashboard-operation" class="menu-element" (click)="closeSidenav(sidenav)">
          <mat-icon>bar_chart_4_bars</mat-icon> <span class="pl-5">Dashboard de operaciones</span>
        </mat-list-item>
        <mat-list-item routerLink="/wallet/admin/cash-closing-report" class="menu-element" (click)="closeSidenav(sidenav)">
          <mat-icon>price_check</mat-icon> <span class="pl-5 text-center">Reporte cierre de caja</span>
        </mat-list-item>
        <mat-list-item routerLink="/wallet/admin/compliance-list" class="menu-element" (click)="closeSidenav(sidenav)">
          <mat-icon>policy</mat-icon> <span class="pl-5 text-center">Compliance</span>
        </mat-list-item>
        <mat-list-item routerLink="/wallet/admin/transaction-cashier" class="menu-element" (click)="closeSidenav(sidenav)">
          <mat-icon>attach_money</mat-icon> <span class="pl-5">Abonos/Debitos</span>
        </mat-list-item>
        <mat-list-item routerLink="/wallet/admin/hours-operations" class="menu-element" (click)="closeSidenav(sidenav)">
          <mat-icon>schedule</mat-icon> <span class="pl-5">Horario de operaciones</span>
        </mat-list-item>
        <mat-list-item routerLink="/wallet/admin/balance-cajero" class="menu-element" (click)="closeSidenav(sidenav)">
          <mat-icon>account_balance_wallet</mat-icon> <span class="pl-5">Balance cajero</span>
        </mat-list-item>
        <mat-list-item routerLink="/wallet/admin/user-balance" class="menu-element" (click)="closeSidenav(sidenav)">
          <mat-icon>account_balance_wallet</mat-icon> <span class="pl-5">Movimientos de usuario</span>
        </mat-list-item>
        <mat-list-item routerLink="/wallet/admin/user-segmentation" class="menu-element" (click)="closeSidenav(sidenav)">
          <mat-icon>account_balance_wallet</mat-icon> <span class="pl-5">Roles de usuario</span>
        </mat-list-item>


        <mat-list-item routerLink="/wallet/admin/status-cajero" class="menu-element" (click)="closeSidenav(sidenav)">
          <mat-icon>request_quote</mat-icon> <span class="pl-5">Estatus cajero</span>
        </mat-list-item>
        <mat-list-item routerLink="/wallet/admin/unassigned-transaction" class="menu-element" (click)="closeSidenav(sidenav)">
          <mat-icon>warning</mat-icon> <span class="pl-5">Transacciones Rezagadas CLP</span>
        </mat-list-item>
        <mat-expansion-panel [class.mat-elevation-z0]="true" class="menu-element -ml-2">
          <mat-expansion-panel-header>
            <mat-icon>currency_exchange</mat-icon> <span class="pl-5">Transacciones VES</span>
          </mat-expansion-panel-header>
          <mat-nav-list>
            <mat-list-item routerLink="/wallet/admin/transactions" class="menu-element" (click)="closeSidenav(sidenav)">
              <mat-icon>currency_exchange</mat-icon> <span class="pl-5">Lista de transacciones VES</span>
            </mat-list-item>
            <mat-list-item  [routerLink]="['/wallet/admin/pending-transactions-binance']"  [queryParams]="{ bankType: 'BINANCE'}" class="menu-element" (click)="closeSidenav(sidenav)">
              <mat-icon>currency_bitcoin</mat-icon> <span class="pl-5">Transacciones pendientes Binance</span>
            </mat-list-item>
            <mat-list-item routerLink="/wallet/admin/pending-transactions" class="menu-element" (click)="closeSidenav(sidenav)">
              <mat-icon>schedule</mat-icon> <span class="pl-5">Transacciones pendientes</span>
            </mat-list-item>
            <mat-list-item routerLink="/wallet/admin/completed-ves-transactions" class="menu-element" (click)="closeSidenav(sidenav)">
              <mat-icon>schedule</mat-icon> <span class="pl-5">Transacciones VES completadas</span>
            </mat-list-item>
          </mat-nav-list>
      </mat-expansion-panel>

      <mat-expansion-panel [class.mat-elevation-z0]="true" class="menu-element -ml-2">
        <mat-expansion-panel-header  >
          <mat-icon>atm</mat-icon> <span class="pl-5">Transacciones de retiro FIAT</span>
        </mat-expansion-panel-header>
        <mat-nav-list>
          <mat-list-item  [routerLink]="['/wallet/admin/withdraw-transactions']" class="menu-element" (click)="closeSidenav(sidenav)">
            <mat-icon>exit_to_app</mat-icon> <span class="pl-5">Transacciones de Retiro</span>
          </mat-list-item>
          <mat-list-item  [routerLink]="['/wallet/admin/withdraw-transactions-sent']" class="menu-element" (click)="closeSidenav(sidenav)">
            <mat-icon>exit_to_app</mat-icon> <span class="pl-5">Historial de pago Retiros FIAT</span>
          </mat-list-item>
        </mat-nav-list>
    </mat-expansion-panel>
      <mat-expansion-panel [class.mat-elevation-z0]="true" class="menu-element -ml-2">
        <mat-expansion-panel-header  >
          <mat-icon>currency_bitcoin</mat-icon> <span class="pl-5">Criptomonedas</span>
        </mat-expansion-panel-header>
        <mat-nav-list>
          <mat-list-item routerLink="/wallet/admin/cripto-rate" class="menu-element" (click)="closeSidenav(sidenav)">
            <mat-icon>currency_bitcoin</mat-icon> <span class="pl-5">Tasa negociación</span>
          </mat-list-item>
          <mat-list-item routerLink="/wallet/admin/pending-transactons-cripto" class="menu-element" (click)="closeSidenav(sidenav)">
            <mat-icon>currency_bitcoin</mat-icon> <span class="pl-5">Transacciones Criptomonedas</span>
          </mat-list-item>
          <mat-list-item routerLink="/wallet/admin/manual-transaction" class="menu-element" (click)="closeSidenav(sidenav)">
            <mat-icon>currency_bitcoin</mat-icon> <span class="pl-5">Transacciones Manuales</span>
          </mat-list-item>
        </mat-nav-list>
    </mat-expansion-panel>

    <mat-expansion-panel [class.mat-elevation-z0]="true" class="menu-element -ml-2">
      <mat-expansion-panel-header  >
        <mat-icon>currency_bitcoin</mat-icon> <span class="pl-5">Zelle</span>
      </mat-expansion-panel-header>
      <mat-nav-list>
        <mat-list-item routerLink="/wallet/admin/zelle-rate" class="menu-element" (click)="closeSidenav(sidenav)">
          <mat-icon>currency_bitcoin</mat-icon> <span class="pl-5">Tasa negociación</span>
        </mat-list-item>
        <mat-list-item routerLink="/wallet/admin/pending-transactions-zelle" class="menu-element" (click)="closeSidenav(sidenav)">
          <mat-icon>currency_bitcoin</mat-icon> <span class="pl-5">Transacciones pendientes Zelle</span>
        </mat-list-item>
      </mat-nav-list>
  </mat-expansion-panel>


      <mat-list-item routerLink="/wallet/admin/working-account" class="menu-element" (click)="closeSidenav(sidenav)">
        <mat-icon>work</mat-icon> <span class="pl-5">Cuentas de trabajo</span>
      </mat-list-item>

      <mat-list-item routerLink="/wallet/admin/active-accounts" class="menu-element" (click)="closeSidenav(sidenav)">
        <mat-icon>business</mat-icon> <span class="pl-5">Cuentas activas</span>
      </mat-list-item>

      <mat-list-item routerLink="/wallet/admin/billing-record" class="menu-element" (click)="closeSidenav(sidenav)">
        <mat-icon>library_books</mat-icon> <span class="pl-5">Facturas exentas</span>
      </mat-list-item>

      <mat-expansion-panel [class.mat-elevation-z0]="true" class="menu-element -ml-2">
        <mat-expansion-panel-header  >
          <mat-icon>settings</mat-icon> <span class="pl-5">Configuración</span>
        </mat-expansion-panel-header>
        <mat-nav-list>
          <mat-list-item routerLink="/wallet/admin/cripto-rate" class="menu-element" (click)="closeSidenav(sidenav)">
            <mat-icon>currency_exchange</mat-icon> <span class="pl-5">Tasas de cambio</span>
          </mat-list-item>
          <mat-list-item routerLink="/wallet/admin/set-rates-parameters" class="menu-element" (click)="closeSidenav(sidenav)">
            <mat-icon>toggle_off</mat-icon> <span class="pl-5">Parámetros tasa automática</span>
          </mat-list-item>
          <mat-list-item routerLink="/wallet/admin/set-rates-parameters-bolivares" class="menu-element" (click)="closeSidenav(sidenav)">
            <mat-icon>toggle_off</mat-icon> <span class="pl-5">Parámetros tasa bolivares</span>
          </mat-list-item>
        </mat-nav-list>
    </mat-expansion-panel>


      </ng-container>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content class="sidenav-content">

    <div class="app-sidenav-content">
      <router-outlet></router-outlet>
      <div class="fab-container" *ngIf="userRole=== rolesEnum.aguacate_client">
        <button color="primary" mat-fab class="fab-toggler" (click)="redirectToExternalLink()">
          <i class="material-icons">contact_support</i> <!-- Animation here -->
        </button>
      </div>
    </div>

  </mat-sidenav-content>
</mat-sidenav-container>
